html,
body {
  background-color: #f5f5f5;
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
body {
  /* Set the default minimum width for the body (adjust the value as needed) */
  min-width: 320px;
}

/* Apply styles only for screens larger than or equal to mobile size (adjust the value as needed) */
@media (min-width: 320px) {
  body {
    /* Set the minimum width to be the same as the screen size */
    min-width: 100vw;
  }
}
