.wave {
  background: url("../../assets/images/BackgroundWave.svg") repeat-x;
  position: absolute;
  width: 6400px;
  height: 898px;
  animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 20s ease -1.25s infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  32% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scaleAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -50px, 0);
  }
  50% {
    transform: translate3d(0, 50px, 0);
  }
}
@keyframes blurAnimation {
  0% {
    filter: blur(50px);
  }
  20% {
    filter: blur(40px);
  }
  50% {
    filter: blur(10px);
  }
  75% {
    filter: blur(40px);
  }
  100% {
    filter: blur(40px);
  }
}
@keyframes colorAnimation {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(-30deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
@keyframes colorAnimation2 {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(-30deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
@keyframes rotateAnimation {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@keyframes rotateAnimation2 {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
}
.color-animation {
  animation: colorAnimation 10s infinite;
  animation-delay: -10s;
}
.fade-animation {
  animation: fade 10s infinite;
}
.rotate-animation {
  animation: rotateAnimation 40s infinite;
  animation-delay: -10s;
}
.blur-animation {
  animation: blurAnimation 20s infinite;
  animation-delay: -10s;
}
.scale-animation {
  animation: scaleAnimation 20s infinite;
  animation-delay: -10s;
}
.swell-animation {
  animation: swell 20s ease -1.25s infinite;
  animation-delay: -10s;
}

.color-animation2 {
  animation: colorAnimation2 10s infinite;
  animation-delay: -10s;
}
.fade-animation2 {
  animation: fade 10s infinite;
  animation-delay: -10s;
}
.rotate-animation2 {
  animation: rotateAnimation2 40s infinite;
  animation-delay: -10s;
}
.blur-animation2 {
  animation: blurAnimation 20s infinite;
  animation-delay: -0s;
}
.scale-animation2 {
  animation: scaleAnimation 20s infinite;
  animation-delay: -10s;
}
.swell-animation2 {
  animation: swell 20s ease -1.25s infinite;
  animation-delay: -10s;
}
