.Toastify__toast-container {
  margin-top: 29%;
  position: fixed;
  width: 110%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  /* Add padding to the toast container */
}
.Toastify__toast-body {
  font-size: 16px;
  color: #5b4e77;
  padding-top: 25px;
  padding-bottom: 20px;
  padding-right: 5px;
  padding-left: 10px; /* Add padding to the text inside the toast */
}

@media (max-width: 768px) {
  .Toastify__toast-container {
    margin-top: 40%;
    width: 90%;
    border-radius: 30px;
  }
  .Toastify__toast-body {
    font-size: 16px;
    color: #5b4e77;
    padding: 10px; /* Add padding to the text inside the toast */
  }

  .Toastify__close-button {
    /* Customize the style of the toast close button */
    color: #5b4e77;
    font-size: 30px;
    font-weight: bold;
  }
}
